<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">

        <color-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ siteTitle }}
        </h2>
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Employrefugees') }}
          </b-card-title>
          <b-card-text>
            {{ $t('check your email and enter your code here') }}
          </b-card-text>

          <!-- form -->

          <b-form class="auth-register-form mt-1">
            <vx-input
              v-model="code"
              label="Code"
              type="number"
            />

            <b-button
              variant="primary"
              block
              @click.prevent="CheckCode"
            >
              {{ $t('Sign up') }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t('Already have an account?') }}</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ $t('Sign in instead') }}
                {{ email }}
              </span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import ColorLogo from '@/layouts/components/ColorLogo.vue'

export default {
  components: {
    ColorLogo,
  },
  data() {
    return {
      code: '',
      email: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    siteTitle() {
      return this.$store.getters['app/title']
    },
  },
  created() {
    this.email = this.$route.params.email
  },
  methods: {
    CheckCode() {
      this.$store.dispatch('auth/CheckCode', { code: this.code, email: this.email }).then(response => {
        this.$router.push({ name: 'register-email', params: { email: this.email, link: response.data.link } })
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
